import '../App.css';
import React from 'react';
import {Link } from "react-router-dom";

function About() {
    
    return (
        <div><img alt="someone" src={`${process.env.PUBLIC_URL +'/img/core-img/someonemain.png'}`}/>
        <section className="about-us-area pt-5 pb-5 letit">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-15">
                        <h2 className="tagline">CHTWN Planet</h2>
                         <p>We defy categorization, as our approach diverges from the norm in every aspect. </p>

<p>One might liken us to the primal shard that fractured from the cosmic blaze, birthing the universe itself. 
<br/>Even this analogy falls short of encapsulating our essence. Our methods defy convention.</p>

<p>We peddle whatever catches our fancy, in quantities as capricious as our whims. <br/>At times, we liberally distribute products gratis, while at others, we donate the entirety of our proceeds to causes close to our hearts. </p>
<p>Admittedly, we occasionally reserve a portion of profits for ourselves, after allocating our esteemed government allies their due share. They call it taxes.</p>

<p> We adorn some wares with our emblem, while leaving others unadorned. We staunchly resist confinement to any one classification. <br/>Below, behold our rendition of a pigeonhole.<br/>
                        <img alt="pidgeonhole" src={`${process.env.PUBLIC_URL +'/img/core-img/pidgeonhole.png'}`}/> </p>
                       <p>If the image fails to resemble a pigeonhole to your eyes, then you truly understand our ethos.<br/> Feel free to click on the <Link to='/shop'>Shop</Link> link and peruse our offerings.</p>
                        <p>And if you're still puzzled by our enigmatic nature, fret not—just click on the <Link to='/shop'>Shop</Link> link and indulge in a purchase.</p>
                    </div>
                </div>
            </div>
        </section> 
        </div>
    );
}

export default About;
