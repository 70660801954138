import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function SHeader() {
  return (
    <>
        <Navbar id="mainvav" expand="lg" className="bg-body-tertiary pt-3 pb-3" bg="light" variant="light" data-bs-theme="light" sticky="top">
        <Navbar.Brand href="/"><img src={`${process.env.PUBLIC_URL +'/img/core-img/logo.png'}`} alt=""/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav"  className="justify-content-end">
            <Nav className="me-auto">
                <Nav.Link className="strikeHeader" href="/">Home</Nav.Link>
                <Nav.Link className="strikeHeader" href="/about">About</Nav.Link>
                <Nav.Link className="strikeHeader" href="/shop">Shop</Nav.Link>
                <Nav.Link className="strikeHeader" href="/contact">Contact</Nav.Link>
            </Nav>
        </Navbar.Collapse>
        </Navbar>
    </>
  );
}

export default SHeader;
