export const ProductList = {
  'signedbasketball': {
    id: 'signedbasketball',
    name: 'GOATS Signed Ball',
    unitName: "GOATS Signed Ball",
    description: "Basketball signed by the 20 greatest alien basketballers",
    img: `${process.env.PUBLIC_URL +'/img/core-img/ballproduct.png'}`,
    cost: 250,
  }
}
