import React from 'react';
import {addToCart} from "../actions/actionCreators";

function Product({item}) {    
    
  return (     
        <div className="p-3 mt-3">
            <div className="row d-flex justify-content-around  mb-3">   
                <img src={item.img}/> 
            </div>  
            <div className="row price d-flex justify-content-around">   
                  {item.unitName}
            </div>  
            <div className="row description justify-content-around p-3">   
                  {item.description}
            </div> 
            <div className="row price d-flex justify-content-around mb-1">   
                  ${item.cost} GOOD$
            </div>  
            <div className="row d-flex justify-content-around mb-1">  
             <i>limit one per customer</i>
            </div>   
        </div> 
  );
}

export default Product;
