import '../App.css';
import Product from './product';
import {ProductList} from '../assets/productlist';
import { useSelector, useDispatch} from 'react-redux';
import {useNavigate} from "react-router-dom";
import { notification } from 'antd';
import {addToCart, clearCart} from "../actions/actionCreators";

function Shop() {
    const navigate = useNavigate(); // <-- use hook in component
    const [api, contextHolder] = notification.useNotification();
    
    const dispatch = useDispatch();  
    const ball = ProductList['signedbasketball'];
    
    const checkout =()=>{
        addItem();
        navigate("/checkout");
    };
   
    const addItem =()=>{
        const data = {
                id: ball.id
                ,unitName: ball.unitName
                ,qty: 1
                ,size: "N/A"
                ,cost: parseInt(ball.cost)
        };
        dispatch(clearCart());
        dispatch(addToCart(data)); 
    }
    
    return (
        <>        
        {contextHolder}
            <section>
                    <div className="shoprow justify-content-around">   
                            <Product item={ball}/>
                    </div>
            </section>
            <section>
                    <div className="shoprow justify-content-around mb-3">   
                      <button className=" alazea-btn-sm mt-15"><span className="mr-2" onClick={checkout}>Checkout</span><span className="lnr lnr-arrow-right"></span></button>
                    </div>
            </section>
        </>
    );
}

export default Shop;
