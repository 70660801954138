import React from 'react';

function SFooter() {
  return (		
    <footer id="chtwnfooter" className="footerArea bg-img">
            <div className="container">
                <div className="row mt-3">
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="single-footer-widget">
                            <div className="footer-logo mb-30">
                                <a href="#"><img src={`${process.env.PUBLIC_URL +'/img/core-img/logo.png'}`} alt=""/></a>
                            </div>                      
                            <div className="social-info">
                                <a href="https://twitter.com/strike_motor"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                <a href="https://www.instagram.com/strike_motor/"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="single-footer-widget">
                            <div className="widget-title mb-2 mt-1">
                                <h3>CONTACT</h3>
                            </div>

                            <div className="contact-information">
                                <p><span>For support:</span> info@chtwn.com</p>
                                <p><span>Press enquiries:</span> press@chtwn.com</p>
                                <p><span>To sue us:</span> no-reply@chtwn.com</p>
                                <p><span>Powered By:</span><a target= "_blank" href="https://goodusd.com"><b> GOOD$</b></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </footer>
  );
}

export default SFooter;
