import '../App.css';
import Product from './product';
import React, { useState, useEffect } from 'react';
import {ProductList} from '../assets/productlist';
import { useSelector, useDispatch} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {Row, Col, notification, Button } from 'antd';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {addToCart} from "../actions/actionCreators";
import * as d3 from "d3";
import '../css/BlurScreen.scss';  

function Home() {
    const navigate = useNavigate(); // <-- use hook in component
    const [api, contextHolder] = notification.useNotification();
    
    const dispatch = useDispatch();  
    const ball = ProductList['signedbasketball'];
    
    const shop =()=>{
       addItem();
       navigate("/shop");
    };
   
    const addItem =()=>{
        const data = {
                id: ball.id
                ,unitName: ball.unitName
                ,qty: 1
                ,size: "N/A"
                ,cost: parseInt(ball.cost)
        };
        dispatch(addToCart(data)); 
    }
    
    
    let svg = undefined;
    let rect = undefined;
    let i = 0;    
   // const [particleStarted, setParticleStarted] = useState(false);
   //const displayFollower =()=>
   //{
   //    console.log("running");
   //    let chtwnfooter = d3.select('#chtwnfooter').node().getBoundingClientRect().height;
   //    let mainvav = d3.select('#mainvav').node().getBoundingClientRect().height;
   //    let removeHeight  = chtwnfooter+mainvav;
   //    let width = Math.max(960, window.innerWidth),
   //        height = window.innerHeight + removeHeight;
   //    console.log("total = "+ height+" removeHeight "+removeHeight+" chrwn " +chtwnfooter+ " mainvav "+mainvav);
   //
   //    svg = d3.select("#blurscreen")
   //        .attr("width", "100%")
   //        .attr("height", "100%")
   //        .attr("cursor", "pointer")
   //        ;
   //
   //    rect = d3.select("#rectshape")
   //        .attr("width", "100%")
   //        .attr("height", "100%")
   //        .on("ontouchstart" in document ? "touchmove" : "mousemove", particle);
   //        i = 0;
   //        console.log("ivcvc = "+i);
   //
   //}
   //
   //const particle=(event, d)=> 
   //{
   //    console.log(`particle: ${d}`);
   //    let m = d3.pointer(event);
   //
   //    svg.insert("circle", "rect")
   //      .attr("cx", m[0])
   //      .attr("cy", m[1])
   //      .attr("r", 1e-6)
   //      .style("stroke", d3.hsl((i = (i + 1) % 360), 1, .5))
   //      .style("stroke-opacity", 1)
   //    .transition()
   //      .duration(2000)
   //      .ease(Math.sqrt)
   //      .attr("r", 100)
   //      .style("stroke-opacity", 1e-6)
   //      .remove();
   //
   //    //event.preventDefault();
   //}
   //
   //useEffect(()=>{     
   //    if (!particleStarted)
   //    {
   //        displayFollower();
   //        setParticleStarted(true);
   //        window.onresize = displayFollower;
   //    }
   //});
    return (
        <>        
        {contextHolder} 
            <Row>
                <div className="redla"><img src={`${process.env.PUBLIC_URL +'/img/core-img/someonenba.png'}`}/>
                <section className="about-us-area pt-5 pb-5 letit">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            <div className="col-12 col-lg-15">
                                <h2 className="tagline">Alien Baller Who Never Missed a Shot</h2>
                                 
                                 <p> He was known as <b><i>ChtwNtBVrCYj46TytoqmbijL7kwtz1pz5ZShRaEeYn1q</i></b>.<br/>
                                 Despite his tongue-twisting name, he conquered the courts against the greatest ballers. <br/> Not content with breaking their ankles, he compelled 20 of them to sign 1000 basketballs, now available for sale for the first time anywhere in the universe. 
                                 </p>
                                 <h1 className="priceline">250 GOOD$ only. <Button className="pricebtn" type="primary" size="large" onClick={shop}>Buy Now</Button></h1>
                                 <p> <span className="priceinfo"> You can only purchase using the GOOD$ stablecoin. </span>
                                 </p>
                                 <img src={`${process.env.PUBLIC_URL +'/img/core-img/ballsmaller.png'}`}/><br/>
                                 
                                <h2 className="tagline">The Ballers</h2>
                                 <p>
                                  So what are the names of the ballers from CHTWN planet that signed the ball?<br/>
                                  <b><i>Abdul, Bron, Air, Dream, Mamba, Showtime, Diesel, Fundamental, Steph, Jay, Larry, Oscar, Wilt, Bill, Ante, KD, Moses, AI, Elvin, West.</i></b>
                                </p>
                                <p>Any likeness to humans on earth is merely coincidental.
                                 </p>
                                <h2 className="tagline">But Wait! There Is More!</h2>
                                 <p>
                                 What's a good sales pitch without that magical phrase, right?
                                <span style={{"font-size": "30px"}}>&#128513;</span>
                                 </p>                 
                                 <p>                
                                    <b><i>EVERY BALL</i></b> comes with a Non-Fungible Token (NFT) that is registered on the Solana Blockchain!<br/> 
                                    The token is proof of authenticity. The public key of the token is also personally handwritten on the ball by <b><i>ChtwNtBVrCYj46TytoqmbijL7kwtz1pz5ZShRaEeYn1q</i></b>.
                                    </p>
                                <h2 className="tagline">But Wait! There Is Even More!</h2>
                                 <p>                
                                    <b><i>EVERY PURCHASE</i></b> comes with a fungible token that can be traded prior to receipt of the ball and the NFT.<br/>
                                    The token is proof of purchase which the owner can use to specify the physical delivery address for the ball <b>(THESE ARE REAL LIFE BASKETBALLS NOT JUST NFT IMAGES)</b> as well as the wallet address for the NFT.
                                    </p>
                                    <Button className="pricebtn" type="primary" size="large" onClick={shop}>Buy Now</Button>
                            </div>
                        </div>
                    </div>
                </section> 
                </div>
            </Row>
        </>
    );
}

export default Home;
